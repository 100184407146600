<template>
  <form ref="formRef" @submit="onSubmit($event)">
    <Card title="Overview" shadow="none" variant="filled" py="4" mb="6" disablePY opened collapse>
      <Box pt="6" flex spaceX="6">
        <FormControl id="name" v-slot="{ id }" required label="Lever name" width="220px">
          <InputText :id="id" v-model="fields.name" />
        </FormControl>
        <FormControl
          v-if="!isDirectSold"
          id="bid"
          v-slot="{ id }"
          required
          label="Media Bid"
          width="220px"
          :disabled="isOptimizationTypeSelected"
        >
          <Tooltip
            v-if="isOptimizationTypeSelected"
            placement="top"
            minWidth="100%"
            pr="2"
            content="When an automated optimization is selected above, Media OS will automatically set bids for you based on your optimization goal."
          >
            <InputText disabled currency :modelValue="fields.bid" />
          </Tooltip>
          <InputText v-else :id="id" v-model="fields.bid" :mask="decimalMask" currency />
        </FormControl>
        <FormControl id="targetBudget" v-slot="{ id }" required label="Spend Allocator" width="220px">
          <InputText :id="id" v-model="fields.targetBudget" :mask="numberMask" endIcon="percent" />
        </FormControl>
      </Box>
    </Card>

    <Card
      v-if="!isDirectSold && !isEndemicChannel"
      ref="targetingCardRef"
      title="Targeting"
      shadow="none"
      variant="filled"
      py="4"
      mb="6"
      disablePY
      opened
      collapse
    >
      <Box pt="6" flex direction="col" spaceY="6">
        <FormSection title="Select device type">
          <Box flex direction="row" class="gap-6 overflow-x-auto">
            <CheckboxButton
              v-model="fields.deviceType"
              :value="AdGroupSplitConditionValue.DESKTOP"
              name="deviceTypeDesktop"
              title="Desktop"
              icon="desktop_mac"
            />
            <CheckboxButton
              v-model="fields.deviceType"
              :value="AdGroupSplitConditionValue.MOBILE"
              name="deviceTypeMobile"
              title="Mobile"
              icon="mobile_screen_share"
            />
            <CheckboxButton
              v-model="fields.deviceType"
              :value="AdGroupSplitConditionValue.TABLET"
              name="deviceTypeTablet"
              title="Tablet"
              icon="tablet_android"
            />
            <CheckboxButton
              v-model="fields.deviceType"
              :value="AdGroupSplitConditionValue.CTV"
              name="deviceTypeCtv"
              title="CTV"
              icon="connected_tv"
            />
          </Box>
        </FormSection>

        <FormSection title="Select supply type">
          <Box flex direction="row" class="gap-6 overflow-x-auto">
            <CheckboxButton
              v-model="fields.supplyType"
              :value="AdGroupSplitConditionValue.WEB"
              name="supplyTypeWeb"
              title="Web"
              icon="web_asset"
            />
            <CheckboxButton
              v-model="fields.supplyType"
              :value="AdGroupSplitConditionValue.APP"
              name="supplyTypeApp"
              title="App"
              icon="apps"
            />
          </Box>
        </FormSection>

        <FormSection title="Add viewability">
          <FormControl id="viewability" v-slot="{ id }" label="Viewability minimum" width="220px">
            <InputText :id="id" v-model="fields.viewability" :mask="numberMask" endIcon="percent" />
          </FormControl>
        </FormSection>

        <FormSection title="Select inventory">
          <LeverInventoryListsSelector
            selectedItemsLabel="Selected Lists"
            :listItems="inventory"
            :selectedListItems="inventoryListDetailed"
            :disableNotSelectedItems="inventoryListDetailed.length >= 1"
            @listItemSelected="onInventoryListSelect"
            @removeSelectedItem="onInventoryListRemove"
          />
        </FormSection>
      </Box>
    </Card>

    <Card ref="creativesCardRef" title="Creatives" shadow="none" variant="filled" py="4" mb="6" disablePY opened collapse>
      <Box pt="6" flex direction="col" spaceY="6">
        <LeverCreatives
          v-model:leverCreativesIds="fields.creativeIds"
          :adGroupSelectedCreatives="selectedCreatives"
        />
      </Box>
    </Card>

    <Box flex justify="end" spaceX="2" mt="6">
      <Button size="lg" variant="outlined" @click="hideLeverDrawer()">
        Cancel
      </Button>
      <Button type="submit" color="primary" size="lg">
        Save Lever
      </Button>
    </Box>
  </form>
</template>

<script setup lang="ts">
import { Box, Button, Card, CheckboxButton, FormControl, FormSection, InputText, Tooltip } from '@lasso/luikit'
import { ComponentPublicInstance, computed, onMounted, reactive, ref } from 'vue'
import { InventoryList, InventoryListType } from '@lasso/api-activation/activation/legacy'
import { AdGroupSplitConditionOperators, AdGroupSplitConditionValue } from '@lasso/api-activation/activation'
import { decimalMask, numberMask } from '@lasso/shared/consts'

import { useBetterForm, useToast } from '@lasso/shared/hooks'

import { useCardLevers } from '../useCardLevers'

import { AdGroupLever } from '../types'

import { buildLeverFormSchema } from './buildLeverFormSchema'

import LeverCreatives from './LeverCreatives.vue'
import LeverInventoryListsSelector from './LeverInventoryListsSelector.vue'
import { useLeverDrawer } from './useLeverDrawer'

const toast = useToast()

const creativesCardRef = ref<ComponentPublicInstance>()
const targetingCardRef = ref<ComponentPublicInstance>()

const { levers, inventory, selectedCreatives, isOptimizationTypeSelected, isDirectSold, isEndemicChannel, channelId } = useCardLevers()!
const { editableLever, drawerScrollTarget, saveLever, hideLeverDrawer } = useLeverDrawer()!

const validationSchema = computed(() => {
  return buildLeverFormSchema({
    levers: levers.value,
    optimizationSelected: isOptimizationTypeSelected.value,
    isDirectSold,
    channelId: channelId.value,
  })
})

const {
  useFieldModels,
  handleSubmit,
  formRef,
} = useBetterForm({
  validationSchema,
  initialValues: ref(editableLever.value as AdGroupLever),
  onMissingField: toast.error,
})

const fields = reactive(useFieldModels([
  'id',
  'localId',
  'name',
  'bid',
  'targetBudget',
  'viewability',
  'creativeIds',
  'deviceType',
  'supplyType',
  'inventoryList',
]))

const inventoryListDetailed = computed(() => inventory.value.filter(item => fields.inventoryList.find(({ id }) => id === item.id)))

const inventoryListTypeOperator = {
  [InventoryListType.Whitelist]: AdGroupSplitConditionOperators.ALL,
  [InventoryListType.Blacklist]: AdGroupSplitConditionOperators.NOT_IN,
}

const onInventoryListSelect = (item: InventoryList) => {
  if (fields.inventoryList.find(({ id }) => id === item.id)) {
    fields.inventoryList = fields.inventoryList.filter(({ id }) => id !== item.id)
  }
  else {
    fields.inventoryList = [...fields.inventoryList, {
      id: item.id,
      operator: inventoryListTypeOperator[item.inventoryListType],
    }]
  }
}

const onInventoryListRemove = (item: InventoryList) => {
  fields.inventoryList = fields.inventoryList.filter(({ id }) => id !== item.id)
}

const onSubmit = handleSubmit(values => saveLever(values))

const scrollToElement = (element?: HTMLElement) => {
  setTimeout(() => {
    element?.scrollIntoView({
      behavior: 'smooth',
    })
  }, 300)
}

onMounted(() => {
  switch (drawerScrollTarget.value) {
    case 'inventoryLists':
      scrollToElement(targetingCardRef.value?.$el)
      break
    case 'creatives':
      scrollToElement(creativesCardRef.value?.$el)
      break
    default:
      break
  }
})
</script>
